export const SOLANA_NETWORK = process.env.NEXT_PUBLIC_SOLANA_NETWORK || 'mainnet'
export const SERVER_URL = process.env.NEXT_PUBLIC_SERVER_URL || 'https://api.thestonedfrogs.io'

export const FRONT_END_URL = "https://staging.lilypad.thestonedfrogs.io";
export const CLIENT_ID = "d98433e9-41ab-4729-9756-bc35a65c17d2";

export const CLIENT_NAME = "TSF Testing";
export const PROJECT_NAME = "Testing Platform";
export const TWITTER_USERNAME = "TheStonedFrogs";
export const GTAG_ID = "G-9SP84E07ZE";

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyC4JCUr_8mv35ZVaU2WGQXFdwd1uIuz9ME",
  authDomain: "the-stoned-fogs.firebaseapp.com",
  projectId: "the-stoned-fogs",
  storageBucket: "the-stoned-fogs.appspot.com",
  messagingSenderId: "821157775752",
  appId: "1:821157775752:web:6078d0752348cbf10dd23d",
  measurementId: "G-9SP84E07ZE",
}

export const COLORS = {
  light: {
    primary: "#ffffff",
    secondary: "#5c9550",
    background: 'white',
  },
  dark: {
    primary: "#672668",
    secondary: "#ffffff",
    background: '#111827',
  },
};
export const S3_FOLDER_PATH =
  "https://s3.us-west-1.amazonaws.com/cdn.thestonedfrogs.io/_assets/tsf_testing/";

export const EXTRA_MENU = [];
